import { BrowserRouter as Router, Routes, Link, Route, Redirect, Navigate, useLocation } from 'react-router-dom';
import MainPage from '../components/MainPage';
import logo from '../logo.svg';
import './App.css';
import Userfront, { LoginForm, PasswordResetForm, LogoutButton } from "@userfront/toolkit/react";
import { IsLoggedIn, HasRole, ProtectedRoute, ProtectedData, AnonymousData } from "../services/Auth";
import Banner from '../components/Banner';
import Header from '../components/Header';

Userfront.init("qbjd5zmb");

//D:\Andre\Storage\workspaces\A\Toolbox\Libraries\ReactJs\userlogin-app-userfront
//D:\Andre\Storage\apps\worksmart\github\course_juniorToSeniorDevRoadmap

function App() {
  return <MainPage />;
}

export default App;
