import React, { Component } from 'react';
import banner from '../images/banner.png';
import './Banner.css';

class Banner extends Component {
    render() {
        return <div className="img-container"></div>
    }
}

export default Banner;