import React, { Component } from 'react';
import Header from '../components/Header';
import './MainPage.css';

class MainPage extends Component {
    render() {
        return <div id="main-page-container">
            <Header />
        </div>
    };
}

export default MainPage;