import React, { Component } from 'react';
import './RadioButton.css';

class RadioButton extends Component {
    handleOnchange(e, amount) {
        this.props.optionChangedCallback(e.target.value, amount);
    }
    render() {
        return <span className="radio-button-wrapper">
            <input type="radio"
                id={this.props.group + "_" + this.props.name}
                name={this.props.group}
                value={this.props.name}
                checked={this.props.selectedValue === this.props.name}
                onChange={(e) => this.handleOnchange(e, this.props.amount)} />
            <label htmlFor={this.props.group + "_" + this.props.name}>
                {this.props.option.url != null ? <div><a href={this.props.option.url} target='_blank'>{this.props.displayName}</a></div> : <div>{this.props.displayName}</div>}
                <div className="radio-detail">
                    <span className="radio-detail-label">Price:</span><span>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.amount)}</span>
                </div>
            </label>
        </span>
    }
}

export default RadioButton;