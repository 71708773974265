import React, { Component } from 'react';
import RadioButton from './RadioButton';
import './OptionsList.css';

class OptionsList extends Component {
    componentDidMount() {
        let defaultOption;
        if(this.props.selection == null) {
            defaultOption = this.props.options
                .filter(x => x.selected === true)[0];
            this.props.optionChangedCallback(defaultOption.name, defaultOption.amount);
        }
    }
    render() {
        let options = [];

        this.props.options.forEach(x => {
            options.push(<RadioButton {...this.props} key={x.name} group={this.props.name} name={x.name} displayName={x.displayName} amount={x.amount} option={x} />)
        });

        return <div>
            <div className="heading h2">{this.props.header}</div>
            <form>
                {options}
            </form>
        </div>
    }
}

export default OptionsList;