import Option from "../models/Option";
import { IsUser, IsUserId } from "../services/Auth";

export const OPTIONS = () => {
    return {
        Airfare: new Option("Airfare", "CLT to Portugal Itinerary", 1021.40, true, "https://drive.google.com/file/d/1-f5ponDsM4uCFWse9P9DIQNhSEYPg5BX/view"),
        Shuttle1: new Option("Shuttle1", null, (IsUser(["j_edwards1211@email.campbell.edu"]) == true ? 0 : 10.90), true, null),
        CarRental: new Option("CarRental", null, (IsUser(["j_edwards1211@email.campbell.edu"]) == true ? 56.66 : 124.59), true, null),
        CastleOfSilves: new Option("CastleOfSilves", null, 3.05, true, "https://www.portugaltravel.org/silves-castle-algarve", true),
        FoodTour: new Option("FoodTour", null, 92.68, true, "https://treasuresoflisboa.com/", true),
        Fuel: new Option("Fuel", null, (IsUser(["j_edwards1211@email.campbell.edu"]) == true ? 19.17 : 42.17), true, null),
        JeepSafari: new Option("JeepSafari", null, 38.72, true, "https://www.tripadvisor.com/AttractionProductReview-g189112-d16910928-Half_Day_Tour_with_Jeep_Safari_in_the_Algarve_Mountains-Albufeira_Faro_District_Al.html", true),
        PortiateBoatTours: new Option("PortiateBoatTours", null, 174.45, true, "https://www.tripadvisor.com/Attraction_Review-g189120-d3221978-Reviews-Portiate_Charters-Portimao_Faro_District_Algarve.html", true),
        RideTram28: new Option("RideTram28", null, 3.38, true, null, true),
        Tolls: new Option("Tolls", null, (IsUser(["j_edwards1211@email.campbell.edu"]) == true ? 7.45 : 16.39), true, null),
        TukTukTour: new Option("TukTukTour", null, 28.40, true, "https://ecotuktours.com/our-tour/lisbon-old-town-tour/", true),
        WineTasting: new Option("WineTasting", null, 32.71, true, "https://arvad.pt/en/visit-us/", true)
    }
}