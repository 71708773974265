import { Navigate, useLocation } from 'react-router-dom';
import Userfront from "@userfront/toolkit/react";
import { jwtDecode } from "jwt-decode";

export const IsLoggedIn = () => !!Userfront.tokens.accessToken;

export const GetUserId = () => {
    if (IsLoggedIn() == false) return null;

    const token = jwtDecode(Userfront.tokens.accessToken);

    return token.userId;
}

export const GetEmail = () => {
    if (IsLoggedIn() == false) return null;

    const accessToken = jwtDecode(Userfront.tokens.accessToken);

    const idToken = jwtDecode(Userfront.tokens.idToken);

    let email = null;

    email = accessToken.email;

    if (email == null || email == "") email = idToken.email;

    return email;
}

export const IsUserId = (userIds) => {
    if (IsLoggedIn() == false) return false;

    const token = jwtDecode(Userfront.tokens.accessToken);

    let userId = token.userId;

    let result = userIds.some(x => x == userId);

    return result;
}

export const IsUser = (users) => {
    if (IsLoggedIn() == false) return false;

    let email = GetEmail();

    let result = users.some(x => x == email);

    return result;
}

export const HasRole = (roles) => {
    if(IsLoggedIn() == false) return false;

    const token = jwtDecode(Userfront.tokens.accessToken);

    const auth = token.authorization;

    if (auth.qbjd5zmb == null) return false;

    const userRoles = auth.qbjd5zmb.roles;

    if (token.email === "habrooksjr@gmail.com" || token.email === "charon.n.brooks@gmail.com") {
        userRoles.push("GroupA");
    }

    if (token.email === "habrooksjr@gmail.com" || token.email === "charon.n.brooks@gmail.com" || token.email === "michaelboydii@outlook.com") {
        userRoles.push("GroupB");
    }

    let result = roles.some(role => userRoles.includes(role));

    return result;
}

export function HideByUser({ children, users }) {
    if (IsLoggedIn() == false) return false;

    let email = GetEmail();

    let result = users.some(x => x === email);

    if (result === true) return null;

    return children;
}

export function ShowByRole({ children, roles }) {
    if(HasRole(roles) === false) return null;

    return children;
}

export function HideByRole({ children, roles }) {
    if (HasRole(roles) === true) return null;

    return children;
}

export function ProtectedRoute({ children }) {
    let location = useLocation();

    if (IsLoggedIn() === false) return <Navigate to="/login" state={{ from: location }} replace />;

    return children;
}

export function HideWhenAnonymous({ children }) {
    if (IsLoggedIn() === false) return null;

    return children;
}

export function ShowWhenAnonymous({ children }) {
    if (IsLoggedIn() === true) return null;

    return children;
}