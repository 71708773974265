import React, { Component } from 'react';
import './Schedule.css'
import * as AppConstants from '../constants/AppConstants';
import Checkbox from '../components/Checkbox';
import { ShowByRole, HideByRole, IsUser } from "../services/Auth";

class Schedule extends Component {
    state = {
        options: null
    };
    componentDidMount() {
        this.setState({ options: AppConstants.OPTIONS() }, () => {
        }) ;
    }
    onCheckBoxChanged(e, optionName) {
        let options = this.state.options;
        options[optionName].selected = e.target.checked;
        this.setState({ options: options }, () => {
            this.props.params.optionSelectionChangeCallback(optionName, e.target.checked);
        });
    }
    render() {
        if (this.state.options === null) return null;
        let params = this.props.params;
        let options = this.state.options;
        let rideTram28Option = options.RideTram28;
        let tukTukTourOption = options.TukTukTour;
        let portiateBoatToursOption = options.PortiateBoatTours;
        let foodTourOption = options.FoodTour;
        let wineTastingOption = options.WineTasting;
        let jeepSafariOption = options.JeepSafari;
        let castleOfSilvesOption = options.CastleOfSilves;
        return <div>
            <div className="heading h1">Trip at a Glance</div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 1 - Sept 23, Monday</div>
                <div>
                    <p>[Travel] Depart from Charlotte Douglas Airport (CLT) @ 5:30 PM</p>
                    <p>[Travel] Depart from Boston (BOS) Airport @ 11:00 PM</p>
                </div>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 2 - Sept 24, Tuesday</div>
                <p>[Travel] Arrive at Humberto Delgado Airport (LIS) @ 10:45 AM</p>
                <p>Shuttle to Pátio do Tijolo @ 11:00 AM</p>
                <p>Drop baggage off at Pátio do Tijolo @ 11:30 AM</p>
                <p>
                    <Checkbox {...this.props}
                        onCheckBoxChanged={(e, optionName) => this.onCheckBoxChanged(e, optionName)}
                        displayName={"Eco Tuk Tour @ 12:30 PM, Duration: 1.5 Hours, Cost: " + Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(tukTukTourOption.amount)}
                        id="tuktuktour"
                        option={tukTukTourOption} />
                </p>
                <p>Check-In Pátio do Tijolo @ 3:00 PM</p>
                <p>Uber to SUB Lisboa @ 7:30 PM</p>
                <p>[Dinner] <a href='https://sudlisboa.com/' target='_blank'>SUB Lisboa @ 8 PM</a></p>
                <p>Uber to Pátio do Tijolo @ 9:30 PM</p>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 3 - Sept 25, Wednesday</div>
                <p>Uber to Martim Moniz @ 6 AM</p>
                <p>
                    <Checkbox {...this.props}
                        onCheckBoxChanged={(e, optionName) => this.onCheckBoxChanged(e, optionName)}
                        displayName={"Ride Tram 28 @ 06:30 AM, Duration: 1 Hours, Cost: " + Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rideTram28Option.amount)}
                        id="ridetram28"
                        option={rideTram28Option} />
                </p>
                <p>Uber to Pátio do Tijolo @ 7:30 AM</p>
                <p>
                    <Checkbox {...this.props}
                        onCheckBoxChanged={(e, optionName) => this.onCheckBoxChanged(e, optionName)}
                        displayName={"Treasures of Lisboa Food Tour @ 10:30 AM, Duration: 4 Hours, Cost: " + Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(foodTourOption.amount)}
                        id="foodtour"
                        option={foodTourOption} />
                </p>
                <p>[Dinner] <a href='https://www.tripadvisor.com/Restaurant_Review-g189158-d1721696-Reviews-Come_Prima_Restaurante_Italiano-Lisbon_Lisbon_District_Central_Portugal.html' target='_blank'>Restaurante Come Prima @ 7:30 PM</a></p>
                <p>[Nightlife] <a href='https://www.cincolounge.com/en/' target='_blank'>Cinco Lounge @ 11 PM</a></p>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 4 - Sept 26, Thursday</div>
                <p>[Travel] Lisbon to the Algarve @ 9 AM (2.5 hours)</p>
                <p>
                    <Checkbox {...this.props}
                        onCheckBoxChanged={(e, optionName) => this.onCheckBoxChanged(e, optionName)}
                        displayName={"Visit Silves Castle @ 01:00 PM, Duration: 2 Hours, Cost: " + Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(castleOfSilvesOption.amount)}
                        id="winetasting"
                        option={castleOfSilvesOption} />
                </p>
                <p>Check-In Vilalara Thalassa Resort @ 3:30 PM</p>
                <p>
                    <Checkbox {...this.props}
                        onCheckBoxChanged={(e, optionName) => this.onCheckBoxChanged(e, optionName)}
                        displayName={"Wine Tasting @ 03:30 PM, Duration: 1.5 Hours, Cost: " + Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(wineTastingOption.amount)}
                        id="winetasting"
                        option={wineTastingOption} />
                </p>
                <ShowByRole roles={["GroupA"]}>
                    <p>[Dinner] <a href='https://vilalara.com/en/restaurants/coral-restaurant/' target='_blank'>Coral Restaurant @ 8 PM</a></p>
                </ShowByRole>
                <HideByRole roles={["GroupA"]}>
                    <p>[Dinner] Free Form/Personal Choice @ 8 PM</p>
                </HideByRole>
                <p>[Nightlife] <a href='https://libertosclub.com/' target='_blank'>Libertos Club @ 11 PM</a></p>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 5 - Sept 27, Friday</div>
                <p>Seven Hanging Valleys Trail @ 8 AM (3 hours)</p>
                <p>
                    <Checkbox {...this.props}
                        onCheckBoxChanged={(e, optionName) => this.onCheckBoxChanged(e, optionName)}
                        displayName={"Portiate Yacht Tour @ 02:30 PM, Duration: 3 Hours, Cost: " + Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(portiateBoatToursOption.amount)}
                        id="portiate"
                        option={portiateBoatToursOption} />
                </p>
                <p>[Dinner] <a href='https://www.facebook.com/restauranteversatile/' target='_blank'>Versatile Restaurante @ 8 PM</a></p>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 6 - Sept 28, Saturday</div>
                <p>Visit Algar Seco @ 9 AM</p>
                <p>Enjoy Praia Da Marinha Beach @ 2 PM</p>
                <p>[Dinner] <a href='https://www.metropolerestaurantebar.com/' target='_blank'>Metropole Restaurante & Bar Lounge @ 8 PM</a></p>
                <p>[Nightlife] <a href='https://www.facebook.com/bluebellbar.albufeira/' target='_blank'>Blue Bell Bar @ 11 PM</a></p>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 7 - Sept 29, Sunday</div>
                <p>
                    <Checkbox {...this.props}
                        onCheckBoxChanged={(e, optionName) => this.onCheckBoxChanged(e, optionName)}
                        displayName={"Jeep Safari in the Algarve Mountains @ 9 AM, Duration: 4 Hours, Cost: " + Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(jeepSafariOption.amount)}
                        id="jeepsafari"
                        option={jeepSafariOption} />
                </p>
                <p>[Dinner] Cooked Dinner In Hotel Suite @ 8 PM</p>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 8 - Sept 30, Monday</div>
                <p>[Travel] Algarve to Lisbon @ 12:00 PM (2.5 hours)</p>
                <p>[Dinner] Free Form/Personal Choice @ 8 PM</p>
            </div>
            <div className='schedule-day-container'>
                <div className="schedule-day-header">Day 9 - Oct 1, Tuesday</div>
                <p>[Travel] Depart from LIS @ 10:00 AM</p>
                <p>[Travel] Depart from JFK @ 2:55 PM</p>
                <p>[Travel] Arrive @ CLT @ 5:30 PM</p>
            </div>
        </div>
    }
}

export default Schedule;