import React, { Component } from 'react';
import Option from '../models/Option';
import OptionsList from './OptionsList';
import './Lodging.css';
import { HasRole } from "../services/Auth";

class Lodging extends Component {
    lisbonOptions = [
        new Option("lodge1NoSelection", "None", 0, false, null),
        //new Option("lrguesthouse", "LR Guest House", 209, true),
        //new Option("casadasjanelascomvista", "Casa das Janelas com Vista", 180.5, false, "https://www.casadasjanelascomvista.com/"),
        new Option("patiodotijolo", "Pátio do Tijolo", (HasRole(["GroupA"]) ? 341 : 290), true, "https://www.patiodotijolo.com/")//,
        //new Option("bairroaltohotel", "Bairro Alto Hotel", 576.50, false, "https://www.bairroaltohotel.com/en/")
    ];
    algarveOptions = [
        new Option("lodge2NoSelection", "None", 0, false, null),
        //new Option("belmar", "Belmar Spa & Beach Resort", 375.50, false, "https://www.belmarresort.com/"),
        //new Option("iberostar", "Iberostar Selection Lagos Algarve", 468.50, false, "https://www.iberostar.com/en/hotels/algarve/iberostar-lagos-algarve/"),
        //new Option("cascade", "Cascade Wellness Resort", 473, false,  "https://www.cascaderesortalgarve.com/"),
        //new Option("palmares", "Palmares Beach House Hotel - Adults Only", 518, false, "https://palmaresliving.com/palmares-beach-house-hotel-book/"),
        //new Option("epicsana", "EPIC SANA Algarve Hotel", 562.5, true, "https://www.sanahotels.com/en/hotel/epic-sana-algarve/"),
        new Option("vilalaara", "Vilalara Thalassa Resort", (HasRole(["GroupB"]) ? 1288.75 : 820), true, "https://www.vilalararesort.com/en/"),
        //new Option("pinecliff", "Pine Cliffs Hotel", 887, false, "https://www.pinecliffs.com/en/"),
        //new Option("conrad", "Conrad Algarve", 1049, false, "https://www.hilton.com/en/hotels/faoapci-conrad-algarve/"),
        //new Option("vilavita", "Vila Vita Parc", 1066.5, false, "https://vilavitaparc.com/en")

        //new Option("villazawaia", "Villa Zawaia", 416, true),
        //new Option("costavillage", "Costa d'Oiro Ambiance Village", 815),
        //new Option("casalagos", "Casa Mae Lagos", 1272),
        //new Option("avenida", "Lagos Avenida Hotel", 1173.90),
        //new Option("conrad", "Conrad Algarve", 2098)
    ];

    //Hotel Clube Porto de Mós - SunPlace Hotels & Beach Resorts
    //Boutique Hotel Vivenda Miranda
    //Villa Doris Suites
    //Hotel Vila Galé Lagos
    //Clube Meia Praia
    //VILA PALMEIRA

    lodge3Options = [
        new Option("lodge3NoSelection", "None", 0, false, null),
        new Option("melia", "Meliá Lisboa Aeroporto", 102.51, true, "https://www.melia.com/en/")
    ];
    render() {
        let params = this.props.params;
        return <div>
            <div className="heading h1">Lodging</div>
            <div>Below is a list of lodging options that Charon and I found desirable. We would like to get the opinion of the group to nail down where we will actually stay. When selecting between the different options, the estimated price total will update to show the change. Email or text me (Andre) to let me know your preferences or if you have have other suggestions.</div>
            <OptionsList
                name="lisbon-lodging"
                header="Lisbon [Bairro Alto] – Sept. 24 to Sept. 26"
                selectedValue={params.lisbonSelectedValue}
                options={this.lisbonOptions}
                optionChangedCallback={(name, value) => params.lisbonSelectionChangedCallback(name, value)} />
            <OptionsList
                name="algarve-lodging"
                header="Algarve – Sept. 26 to Sept. 30"
                selectedValue={params.algarveSelectedValue}
                options={this.algarveOptions}
                optionChangedCallback={(name, value) => params.algarveSelectionChangedCallback(name, value)} />
            <OptionsList
                name="lodge3"
                header="Lisbon [By Airport] – Sept. 30 to Oct. 1"
                selectedValue={params.lodge3SelectedValue}
                options={this.lodge3Options}
                optionChangedCallback={(name, value) => params.lodge3SelectionChangedCallback(name, value)} />
        </div>
    }
}

export default Lodging;