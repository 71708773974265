import React, { Component } from 'react';
import Banner from '../components/Banner';
import Lodging from '../components/Lodging';
import Transportation from '../components/Transportation';
import Schedule from '../components/Schedule';
import PricePanel from '../components/PricePanel';
import { ShowByRole } from "../services/Auth";
import Option from "../models/Option";
import './Content.css';
import * as AppConstants from '../constants/AppConstants';

class Content extends Component {
    state = {
        lisbonLodging: { name: null, amount: null },
        algarveLodging: { name: null, amount: null },
        lodge3: { name: null, amount: null },
        airfare_selected: null,
        airfare_amount: null,
        options: null
    };
    componentDidMount() {
        this.setState({options: AppConstants.OPTIONS()});
    }
    onLisbonLodgingSelectionChange = (name, value) => {
        this.setState({ lisbonLodging: { name: name, amount: value }}, () => {
            this.calculate();
        });
    }
    onAlgarveLodgingSelectionChange = (name, value) => {
        this.setState({ algarveLodging: { name: name, amount: value } }, () => {
            this.calculate();
        });
    }
    onLodge3SelectionChange = (name, value) => {
        this.setState({ lodge3: { name: name, amount: value } }, () => {
            this.calculate();
        });
    }
    onAirfareOptionSelectionChange = (name, amount) => {
        this.setState({ airfare_selected: name, airfare_amount: amount }, () => {
            this.calculate();
        });
    }
    onOptionSelectionChange = (name, selected) => {
        var option = this.state.options[name];
        if(option === null) return;
        option.selected = selected;
        this.state.options[name] = option;
        this.setState({ options: this.state.options }, () => {
            this.calculate();
        });        
    }
    calculate() {
        const { priceChange } = this.props;

        var personal = this.state.lisbonLodging.amount;
        personal += this.state.algarveLodging.amount;
        personal += this.state.lodge3.amount;

        personal += this.state.airfare_amount;

        var transportation = 0;

        var optional = 0;

        let options = AppConstants.OPTIONS();

        for(var key in this.state.options) {
            let option = this.state.options[key];
            if(option.selected === false) continue;
            if (option.name === options.Airfare.name) continue;
            if(option.optional === true) {
                optional += option.amount;
            } else if (option.name === options.Shuttle1.name || option.name === options.CarRental.name || option.name === options.Fuel.name || option.name === options.Tolls.name) {
                transportation += option.amount;
            } else {
                personal += option.amount;
            }
        }

        priceChange(personal, transportation, optional);
    };
    render() {
        let lodgingProps = {
            lisbonSelectedValue: this.state.lisbonLodging.name,
            lisbonSelectionChangedCallback: this.onLisbonLodgingSelectionChange,
            algarveSelectedValue: this.state.algarveLodging.name,
            algarveSelectionChangedCallback: this.onAlgarveLodgingSelectionChange,
            lodge3SelectedValue: this.state.lodge3.name,
            lodge3SelectionChangedCallback: this.onLodge3SelectionChange
        }
        return <div id="content">
            <div>
                <center>
                    <b>
                        <div>Charon’s 40th Birthday Celebration in Portugal</div>
                        <div>Sept. 23 2024 to Oct. 1 2024 (9 days)</div>
                    </b>
                </center>
            </div>
            <div id="body-container">
                <div>
                    <div className="heading h1">Disclaimer</div>
                    <div>We initially targeted dates that encompassed a 7-day span, but the plane tickets were significantly higher.</div>
                    <br /><div>I plan on periodically updating this site throughout the planning process so that everyone can have a central point of reference. Therefore, PLEASE SAVE THE LINK SOMEWHERE WHERE YOU CAN REFERENCE IT OVER AND OVER as I provide updates.</div>
                    <br /><div>The quotes in this document are based on accommodation for a group of 6 individuals. We may end up with less or more than 6 individuals in which I will adjust the amounts accordingly once confirmed.</div>
                    <br /><div>The cost shown below is based on the cost Charon and I paid for our airfare. That cost can fluctuate up or down a bit depending on the time and days you book your airfare. Nevertheless, the cost Charon and I paid should provide a baseline.</div>
                    <br /><div>The lodging costs are based on the occupancy of 2 individuals per unit. If you have the desire to do a room individually, logically the price will increase.</div>
                </div>
                <div>
                    <div className="heading h1">Good to know</div>
                    <ul>
                        <li>The time in Portugal is 5 hours ahead of EST</li>
                    </ul>
                </div>
                <div>
                    <div className="heading h1">What is included in the price?</div>
                    <ul>
                        <li>Airfare (round trip from CLT to Portugal)</li>
                        <li>2 nights @ Lisbon Hotel</li>
                        <li>4 nights @ Algarve Hotel</li>
                        <li>1 night @ Meliá Lisboa Aeroport</li>
                        <li>Car Rental (transportation from Lisbon to Algarve)</li>
                        <li>Vehicle transfer from LIS to Patio do Tijolo (one way)</li>
                    </ul>
                </div>
                <div>
                    <div className="heading h1">What is NOT included in the price?</div>
                    <ul>
                        <li>Vehicle transfer from LIS to Meliá Lisboa Aeroporto (possibly walking distance 5-7 minutes)</li>
                    </ul>
                </div>
                <Schedule params={{ optionSelectionChangeCallback: this.onOptionSelectionChange}} />
                <Transportation params={{ optionSelectionChangeCallback: this.onAirfareOptionSelectionChange, selectedValue: this.state.airfare_selected}} />
                <Lodging {...this.props} params={lodgingProps} />
                <ShowByRole roles={["admin"]}>
                    <div className="heading h1">References</div>
                    <div><a href='https://g.co/kgs/C2tXhAJ' target='_blank'>Euro to USD Converter</a></div>
                    <div><a href='https://wheatlesswanderlust.com/10-days-in-portugal-itinerary/' target='_blank'>How to plan a trip to Portugal</a></div>
                    <div><a href='https://www.joandso.com/location/best-hotels-bairro-alto-lisbon-portugal' target='_blank'>5 Best Hotels in Bairro Alto</a></div>
                    <div><a href='https://smilesonarrival.com/how-to-get-to-lagos-from-lisbon/' target='_blank'>How to get from Lisbon to Lagos – Part 1</a></div>
                    <div><a href='https://juliedawnfox.com/best-ways-to-get-from-lisbon-to-lagos/?v=7516fd43adaa' target='_blank'>How to get from Lisbon to Lagos – Part 2</a></div>
                    <div><a href='https://tollguru.com/toll-calculator-portugal' target='_blank'>Toll and Gas Calculator</a></div>
                    <div><a href='https://www.europcar.com/editorial/en/travel-tips/portugals-toll-roads-everything-you-need-to-know-952/#:~:text=This%20depends%20on%20the%20vehicle,under%20%E2%82%AC20%20in%20tolls' target='_blank'>Portugal's Toll Roads - Everything You Need to Know</a></div>
                </ShowByRole>
            </div>
            <PricePanel price={this.props.price} />
        </div>
    };
}

export default Content;