import React, { Component } from 'react';
import moment from 'moment';

class PaymentSchedule extends Component {
    paymentDates = [
        { date: new Date("03/15/2024"), amount: 0 },
        { date: new Date("04/15/2024"), amount: 0 },
        { date: new Date("05/15/2024"), amount: 0 },
        { date: new Date("06/15/2024"), amount: 0 },
        { date: new Date("07/15/2024"), amount: 0 },
        { date: new Date("08/15/2024"), amount: 0 },
        { date: new Date("09/15/2024"), amount: 0 }
    ]
    getNextLastPaymentDate(value, mode) {
        value = new Date(value.getFullYear(), value.getMonth(), value.getDate());

        let date = new Date(value.getFullYear(), value.getMonth(), 15);


        if (value > date) {
            if (mode === 0) {
                // do nothing                
            } else {
                date = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
            }
        }

        return date;
    }
    caculatePayments() {
        let total = this.props.total;
        
        let numberOfPayments = this.paymentDates.length;

        var paymentAmt = Math.floor(total / numberOfPayments * 100) / 100;
        
        var remainingAmt = Number((total - (paymentAmt * numberOfPayments).toFixed(2)).toFixed(2));

        this.paymentDates.forEach((x) => {
            x.amount = paymentAmt;
        });

        this.paymentDates[this.paymentDates.length - 1].amount += remainingAmt;
    }
    render() {
        this.caculatePayments();

        const datesFragment = this.paymentDates.map((x, index) => (
                <tr key={index}>
                    <td>{moment(x.date).format("MM/DD/yyyy")}</td>
                    <td>{x.amount}</td>
                </tr>
            )
        );

        return <div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {datesFragment}
                    <tr>
                        <td></td>
                        <td>{this.paymentDates.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    };
}

export default PaymentSchedule;