import React, { Component } from 'react';
import { ShowByRole, GetEmail, IsUser } from "../services/Auth";
import Option from '../models/Option';
import OptionsList from './OptionsList';
import * as AppConstants from '../constants/AppConstants';

class Transportation extends Component {
    render() {
        let options = AppConstants.OPTIONS();
        let defaultOption = options.Airfare;
        let params = this.props.params;
        let shuttleVerbiage = "";
        if (options.Shuttle1.amount > 0) {
            shuttleVerbiage = <div>"Shuttle Transfer from LIS to Pátio do Tijolo House on Sept. 24 ({Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(options.Shuttle1.amount)})<br /><br /></div>;
        } else {
            shuttleVerbiage = null;
        }
        return <div>
            <div className="heading h1">Transportation</div>
            {/* <div className="heading h2">Airfare</div> */}
            {/* <a href='https://drive.google.com/file/d/1-_8T9Cb5vTm0m63Biv74T4R5zxbIk_bS/view?usp=drive_link' target="_blank">CLT to Portugal Itinerary</a> */}
            {/* <span>&nbsp;&nbsp;($1021.40)</span> */}

            <OptionsList
                name="Airfare"
                header="Airfare"
                selectedValue={params.selectedValue}
                options={[
                    new Option("airfareNoSelection", "None", 0, false, null),
                    defaultOption
                ]}
                optionChangedCallback={(name, value) => params.optionSelectionChangeCallback(name, value)} />

            <div className="heading h2">Vehicle</div>
            <ShowByRole roles={["admin"]}>
                <div>https://www.viator.com/tours/Lisbon/Lisbon-Airport-Private-Transfer-Round-Trip/d538-62574P38?medium=social-share-copy</div>
                <br />
            </ShowByRole>
            {shuttleVerbiage}
            <div>
                <p>Car rental from Sept. 25 to Sept. 30 ($999.05).</p>
                <hr></hr>
                <ul>
                    <li>Rental fee - $679.65</li>
                    <li>Fuel - $230</li>
                    <li>Tolls - $89.40</li>
                </ul>

                <p>Individual contribution for car rental ({Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(options.CarRental.amount + options.Fuel.amount + options.Tolls.amount)}).</p>
                <hr></hr>
                <ul>
                    <li>Rental fee - {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(options.CarRental.amount)}</li>
                    <li>Fuel - {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(options.Fuel.amount)}</li>
                    <li>Tolls - {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(options.Tolls.amount)}</li>
                </ul>
            </div>
            <ShowByRole roles={["admin"]}>
                <br />
                <a href='https://www.rentalcars.com/?affiliateCode=thewayfo946&adcamp=best-ways-to-get-from-_amcid-M7BiPiiEVZVFZIU6gqZrB' target="_blank">Rentalcars</a>
                <br />
                <a href='https://www.discovercars.com/?a_aid=juliedawnfox&data2=best-ways-to-get-from-lisbon-to-lagos_amcid-FNrJEF6MZUe2FNNxMFo2Y' target="_blank">Discovercars</a>
            </ShowByRole>
        </div>
    }
}

export default Transportation;