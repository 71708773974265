import React, { Component } from 'react';
import './PricePanel.css';

class PricePanel extends Component {
    render() {
        return <div className="price-panel-container">
            <div className='panel'>
                <div className='field'>
                    <div className='label'>Flight & Hotel</div>
                    <div className='value'>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.price.personal)}</div>
                </div>
                <div className='field separator'>
                    <div className='label'></div>
                    <div className='value'>+</div>
                </div>
                <div className='field'>
                    <div className='label'>Transportation</div>
                    <div className='value'>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.price.transportation)}</div>
                </div>
                <div className='field separator'>
                    <div className='label'></div>
                    <div className='value'>+</div>
                </div>
                <div className='field'>
                    <div className='label'>Optional</div>
                    <div className='value'>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.price.optional)}</div>
                </div>
                <div className='field separator'>
                    <div className='label'></div>
                    <div className='value'>=</div>
                </div>
                <div className='field'>
                    <div className='label'><b>Total</b></div>
                    <div className='value'>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.price.personal + this.props.price.transportation + this.props.price.optional)}</div>
                </div>
            </div>
        </div>  
    }
}

export default PricePanel;