import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Link, Route, Redirect, Navigate, useLocation } from 'react-router-dom';
import Userfront, { LoginForm, PasswordResetForm, LogoutButton } from "@userfront/toolkit/react";
import { IsLoggedIn, HasRole, ProtectedRoute, HideWhenAnonymous, ShowWhenAnonymous, ShowByRole } from "../services/Auth";
import Banner from './Banner';
import Content from './Content';
import './Header.css';
import PaymentSchedule from './PaymentSchedule';

Userfront.init("qbjd5zmb");

const state = {
    total: 0
}

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: {
                personal: 0,
                transportation: 0,
                optional: 0
            },
            total: 0
        };
        this.onPriceChange = this.onPriceChange.bind(this);
    }
    onPriceChange(personal, transportation, optional) {
        this.setState({ price: { personal: personal, transportation: transportation, optional: optional } });
    }
    render() {
        return(<div>
            <Banner />
            <Router>
            <div>
                <nav>
                    <ul>
                        <ShowWhenAnonymous><li><Link to="/login">Login</Link></li></ShowWhenAnonymous>
                        <HideWhenAnonymous><li><Link to="/logout">Logout</Link></li></HideWhenAnonymous>
                        <li><Link to="/itinerary">Itinerary</Link></li>
                        {/* <li><Link to="/pmtsched">Payment Schedule</Link></li> */}
                    </ul>
                </nav>
                <Routes>
                <Route path="/login" element={<LoginForm />} />
                <Route path="/logout" element={<ProtectedRoute><LogoutButton /></ProtectedRoute>} />
                <Route path="/reset" element={<PasswordResetForm />} />
                <Route path="/itinerary" element={<ProtectedRoute><Content price={this.state.price} priceChange={this.onPriceChange} /></ProtectedRoute>} />
                {/* <Route path="/pmtsched" element={<PaymentSchedule total = {this.state.total} />} /> */}
                <Route path="*" element={<LoginForm />} />
                </Routes>
            </div>
            </Router>
        </div >);
    }
}

export default Header;