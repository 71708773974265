import React, { Component } from 'react';
import './Checkbox.css';

class Checkbox extends Component {
    handleOnchange(e, optionName) {
        this.props.onCheckBoxChanged(e, optionName);
    }
    render() {
        const { option, displayName } = this.props;
        var name = option?.name;
        var displayNameParm = displayName ?? option?.displayName;
        var url = option?.url;
        var display = (displayNameParm != null && displayNameParm != "" ? displayNameParm : name);
        var labelInnerHtml = null;
        if (url !== null) {
            labelInnerHtml = <a href={url} target='_blank'>{display}</a>;
        } else {
            labelInnerHtml = display;
        }
        return <span className="checkbox-wrapper">
            <input type="checkbox"
                id={this.props.id}
                checked={option?.selected ?? false} 
                onChange={(e) => this.handleOnchange(e, name)} />
            <label htmlFor={this.props.id}>{labelInnerHtml}</label>
        </span>
    }
}

export default Checkbox;